:root {
  --dev-bg: var(--white);
  --odd-list: #f0f8ff;
  --even-list: #e7f3fe;
  --li-hover-pink: #ffe6e6;
  --banner-lightBlue-bg: var(--php);
  --banner-blue-bg: var(--css);
  --banner-red-bg: var(--java);
}

/* Body */
.DevPageLayout {
  width: 100%;
}

/* Pagination */
.pagination-controls {
  margin-bottom: 150px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  font-family: 'Jost', sans-serif;
  font-size: 15px;
  margin-top: 50px;
  border-bottom: 1px solid #a2a2a288;
  padding-bottom: 10px;
}

.pagination-controls button {
  cursor: pointer;
  border: none;
  outline: none;
  background-color: var(--orange);
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  border-radius: .20rem;
}

.pagination-controls button:disabled {
  cursor:not-allowed;
}

/* Dev SideBar Menu */
.DevSideBarMenu {
  height: auto;
  background-color: var(--dev-bg);
}

.content-nav {
  padding: 0;
  list-style: none;
  background-color: var(--even-list);
  height: 100%;
  position: relative;
  padding-bottom: 100px;
}

.content-nav li {
  padding: 2px 15px;
  transition: all .1s ease-in-out;
}

.content-nav li:nth-child(1) {
  padding-top: 10px;
}

.content-nav .skeleton {
  height: 28px;
}

.content-nav li a {
  display: block;
  width: 100%;
  height: 35px;
  display: flex;
  align-items: center;
  padding: 0 15px;
  text-transform: uppercase;
  text-decoration: none;
  color: var(--orange);
}

.content-nav li:nth-of-type(even) {
  background-color: var(--even-list);
}

.content-nav li:nth-of-type(odd) {
  background-color: var(--odd-list);
}

.content-nav li:hover {
  background-color: var(--li-hover-pink);
}

/* Back To Site Btn */
.back-to-site-dev {
  position: fixed;
  left: 0;
  bottom: 20px;
}

.back-to-site-dev .btn-back-to-site {
  border: none;
  outline: none;
  border-radius: 0 20px 20px 0;
  background-color: var(--orange);
  color: var(--white);
  padding: 5px 12px;
  font-size: 16px;
  font-family: 'jost', sans-serif;
  box-shadow: 2px 2px 10px -3px var(--black);
  cursor: pointer;
  opacity: .5;
  transition: opacity .1s ease-in-out;
}

.back-to-site-dev .btn-back-to-site:hover {
  opacity: 1;
}

/* Dev Header */
.DevHeader {
  width: 100%;
  margin: 0;
  padding: 0;
  display: inline-flex;
}

.DevHeader .logo {
  width: 20%;
  min-width: 18%;
  height: 105px;
}

.DevHeader .banner {
  width: 100%;
  height: 60px;
  /* background-color: var(--background); */
  /* background-image: linear-gradient(
    158deg,
    var(--banner-lightBlue-bg) -40%,
    var(--banner-blue-bg),
    var(--banner-red-bg) 85%
  ); */
  background-image: linear-gradient(to left, #ee9174, #f38b91, #eb8baf, #d791ca, #b99bdd, #a1a8ec, #86b4f6, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
  /* background-image: linear-gradient(to right, #4169e1, #9a5ad0, #cc49b4, #eb4091, #f84a6c, #f54f5b, #ef554a, #e85c3a, #df5137, #d64635, #cd3b32, #c42f30); */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}

.DevHeader .banner > div {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.DevHeader .banner > div form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 450px;
  height: 32px;
}

.DevHeader .banner form input {
  width: 100%;
  height: 100%;
  height: 100%;
  padding: 0 10px;
  border: none;
  outline: none;
  font-family: "Jost", sans-serif;
}

.DevHeader .banner form button {
  height: 100%;
  padding: 0 20px;
  border: none;
  outline: none;
  background-color: #4169e1;
  color: var(--white);
  text-transform: uppercase;
  font-size: 15px;
  font-family: "Jost", sans-serif;
  cursor: pointer;
}

.DevHeader .banner .social {
  margin: 0 5px;
}

.DevHeader .banner .social a {
  font-size: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
  border-radius: 50%;
}

.DevHeader nav {
  width: 100%;
  display: flex;
  align-items: center;
}

.DevHeader nav ul {
  width: 100%;
  height: 45px;
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  /* background-color: var(--banner-lightBlue-bg); */
  background-color: #4169e1;
}

.DevHeader nav ul li {
  padding: 5px;
}

.DevHeader nav ul li a {
  display: block;
  height: 100%;
  padding: 0 15px;
  color: var(--white);
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  transition: all .1s ease-in-out;
}

.DevHeader nav ul li a:hover {
  background-color: #5a8dee;
}

/* Dev Right Side */
.dev__main__content__container {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.dev__main__content__container > section:nth-child(1),
.dev__main__content__container > aside:nth-child(3){
  width: 18% !important;
}

.dev__main__content__container > section:nth-child(2) {
  width: 64%;
}

/* Main Outlets Section */
.dev__main__content__container .section {
  border: 1px solid;
  width: 100% !important;
  padding: 20px;
}

.dev__main__content__container .section > div {
  width: 100% !important;
}

.dev__main__content__container .section .head {
  border-bottom: 1px solid #5558;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: 12px;
}

.dev__main__content__container .section .head .dev-page-heading {
  margin: 0;
  font-size: 22px;
  font-family: 'Jost', sans-serif;
  font-weight: 500;
  letter-spacing: 1px;
  padding: 0 0 2px 3px;
  white-space: nowrap; /* Ensures text doesn't wrap */
  overflow: hidden; /* Hide any overflow text */
  text-overflow: ellipsis; /* Add ellipsis (...) for overflowed text */
}

.dev__main__content__container .section .head .view__all__btn button {
  border: none;
  outline: none;
  background-image: linear-gradient(to right, #4169e1, #9a5ad0, #cc49b4, #eb4091, #f84a6c, #f54f5b, #ef554a, #e85c3a, #df5137, #d64635, #cd3b32, #c42f30);
  background-size: 170% 100%;
  border-radius: 20px;
  color: var(--white);
  padding: 4px 15px;
  font-size: 14px;
  cursor: pointer;
  transition: all .2s ease;
  box-shadow: 0px 3px 5px -2px var(--black);
}

.dev__main__content__container .section .head .view__all__btn button:hover {
  background-size: 250% 500%; /* Shift to expose more of the red gradient */
}

/* Search List Style */
.section .search-list-container ul {
  list-style-type: none;
  margin: 0;
  padding: 10px 0;
}

.search-list-container ul .search-list {
  border: 1px solid #e7f3febb;
  padding: 4px 8px;
  border-radius: .25rem;
  margin-bottom: 10px;
  cursor: pointer;
  transition: all .1s ease-in-out;
  user-select: none;
  -webkit-user-select: none;
}

.search-list-container ul .search-list:nth-of-type(odd) {
  background-color: var(--odd-list);
}

.search-list-container ul .search-list:nth-of-type(even) {
  background-color: var(--even-list);
}

.search-list-container ul .search-list:hover {
  background-color: var(--li-hover-pink);
  border-color: transparent;
}

/* Templete Outlet Style */
.search-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.search-input {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  border-radius: 20px;
  width: 100%;
  border: 1px solid #a2a2a2;
  background-color: var(--white);
}

.search-input .search-icon {
  font-size: 20px;
  margin-right: 10px;
}

.search-input input {
  border: none;
  background: none;
  outline: none;
  font-size: 15px;
  width: 100%;
  padding-right: 20px;
}

.filter select {
  padding: 5px;
  font-size: 16px;
  border: none;
  outline: none;
  border-left: 1px solid #a2a2a2;
}

/* Dev Page Home Outlet */
.DevHomePage .latest__themes {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 13px;
}

.DevHomePage .latest__themes .skeleton {
  height: 150px;
}

.latest__themes .LatestThemeCard {
  height: 150px;
  position: relative;
  overflow: hidden;
  transition: all .2s ease-in-out;
}

.latest__themes .LatestThemeCard:hover {
  transform: translateY(-5px);
  box-shadow: 0 3px 10px -2px var(--parablack);
}

.latest__themes .LatestThemeCard img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.watermark {
  position: relative;
  overflow: hidden;
}

.watermark .watermark__card {
  position: absolute;
  top: -4px;
  right: -19px;
  width: 50px;
  height: 20px;
  background-color: var(--orange);
  color: var(--white);
  transform: rotate(45deg);
  padding-top: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.watermark .watermark__card h1 {
  font-size: 8px;
  letter-spacing: 1px;
  font-weight: 300;
  font-family: 'Jost', sans-serif;
  margin: 0;
  padding: 0;
}

/* Swiper */
.DevHomePage .mySwiper {
  width: 100%;
  height: 200px;
  display: block;
  padding: 2px;
}

.DevHomePage .mySwiper .skeleton {
  height: 170px;
}

.DevHomePage .mySwiper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.DevHomePage .mySwiper .swiper-pagination {
  bottom: -1px;
}

.DevHomePage .swiper-slide {
  border: 1px solid #aaa5;
  padding: 5px;
  border-radius: 3px;
  height: 170px;
}

.DevHomePage .mySwiper .swiper-pagination-bullet {
  background-color: var(--banner-blue-bg);
}

.DevHomePage .mySwiper .swiper-pagination-bullet-active {
  background-color: var(--orange);
}

  /* Elements Page Styling */
.DevElementsPage {
  padding-bottom: 20px;
}

.DevElementsPage .element-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Creates 2 columns of equal width */
  gap: 16px;
}
/* Element Skeleton */
.element-list .element-item.skeleton {
  height: 230px;
}

.element-list .element-item {
  --element-div-radius: 5px;
  width: 100%;
  height: 230px;
  border-radius: 3px;
  padding: 8px;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  text-align: center;
  transition: transform 0.3s ease;
  background-color: #a2a2a233;
  text-decoration: none;
}
/* 
.element-list .element-item:hover {
  transform: translateY(-3px);
} */

.element-list .element-item .img-details-outer {
  position: relative;
  height: 85%;
  border-radius: var(--element-div-radius);
  transition: all .2s ease-in-out;
}

.element-list .element-item .img-details-outer:hover {
  transform: translateY(-3px) scale(1.009);
  box-shadow: 0 2px 10px -2px var(--black2);
}

.element-list .element-item .img-details-outer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: var(--element-div-radius);
}

.element-list .element-item .title-view-div {
  height: 15%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  margin-top: 3px;
}

.element-list .element-item .title-view-div h5 {
  margin: 0;
  font-size: 17px;
  font-weight: 500;
  font-family: 'Jost', sans-serif;
  display: block;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  color: var(--orange);
}

.element-list .element-item .title-view-div .views {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  color: var(--black4);
}

.element-list .element-item .title-view-div .views span {
  display: flex;
  align-items: center;
  justify-content: center;
}

.element-list .element-item .title-view-div .views .views-counter {
  font-size: 13px;
}

/* Styling for the template item */
.templete-list {
  padding: 0;
  margin: 0;
  list-style: none;
}

/* Template Skeleton */
.templete-item.skeleton__outer {
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.templete-item .skeleton {
  height: 150px;
}

.templete-item .skeleton:first-child {
  flex: 1 1 28%;
}

.templete-item .skeleton:last-child {
  flex: 0 0 72%;
}

.templete-item {
  width: 100%;
  display: flex;
  gap: 12px;
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;
  transition: background-color 0.3s ease;
  margin-bottom: 18px;
}

.templete-item .img {
  --templete-img-radius: none;
  width: 200px;
  height: 150px;
  flex-shrink: 0;
  border: 1px solid #aaa5;
  padding: 2px;
  border-radius: var(--templete-img-radius);
}

.templete-item .img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: var(--templete-img-radius);
  user-select: none;
  -webkit-user-drag: none;
}

.templete-item .details {
  flex-grow: 1;
  height: 150px;
  position: relative;
}

.templete-item .language {
  display: flex;
  align-items: center;
  gap: 7px;
  color: var(--orange);
}

.templete-item .language span {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.templete-item .language h5 {
  margin: 0;
  padding: 0;
  font-family: "Calibri", sans-serif; /* Use Calibri for the language section */
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
}

.templete-item a {
  display: block;
  text-decoration: none;
  color: var(--black2);
  transition: color .1s ease-in-out;
}

.templete-item a:hover {
  color: var(--orange);
}

.templete-item .title {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 1px;
  margin: 6px 0;
  font-family: "Jost", sans-serif; /* Using Bebas Neue for titles as per comment */
  display: -webkit-box; /* Necessary for line-clamping */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1; /* Limit text to 2 lines */
  overflow: hidden;
  text-overflow: ellipsis; /* Add ellipsis (...) for overflowed text */
}

.templete-item .short-description {
  font-size: 14px;
  line-height: 18px;
  color: var(--parablack);
  margin: 0;
  margin-bottom: 20px;
  font-family: "Jost", sans-serif; /* Use Jost for paragraphs */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

/* Styling for stats-container */
.templete-item .stats-container {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: end;
  gap: 10px; /* Space between the stat items */
  font-family: 'Arial', sans-serif;
}

.templete-item .stat {
  display: flex;
  align-items: center;
  font-size: 13px;
  font-family: 'jost', sans-serif;
  display: flex;
  align-items: center;
}

.templete-item .icon {
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
}

.templete-item .count {
  font-weight: 400;
  margin-right: 3px;
  color: var(--black4);
}

.templete-item .download-btn {
  color: #007bff;
  cursor: pointer;
  font-family: "Calibri", sans-serif; /* Use Calibri or Jost for buttons */
  font-size: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease; /* Add transition for transform and box-shadow */
  border-radius: 50%;
}

.templete-item .download-btn:hover {
  color: var(--white);
  background-color: #0056b3; /* Darker background on hover */
  transform: translateY(-3px); /* Lift effect on hover */
  box-shadow: 0 4px 12px rgba(0, 123, 255, 0.3); /* Darker shadow on hover */
}

/* Templetes Modal */
.Modal_Container {
  position: fixed;
  top: 7px;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 99;
  overflow-y: auto;
  background-color: var(--white);
  border-radius: 12px 12px 0 0;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, opacity 0.3s ease;
  margin: 0 auto;
  opacity: 0;
  transform: translateY(20px);
}

.Modal_Container.open {
  opacity: 1;
  transform: translateY(0);
}

.Modal_Container.closing {
  opacity: 0;
  transform: translateY(20px);
}

.Modal_Container .head_bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--black4);
  padding: 0 20px;
  height: 30px;
}

.Modal_Container .head_bar .logo {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Modal_Container .head_bar .logo img {
  width: 100%;
  height: 100%;
}

.Modal_Container .head_bar .close_btn {
  border: 1px solid transparent;
  outline: none;
  color: var(--white);
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 23px;
  height: 23px;
  border-radius: 50%;
  font-size: 22px;
  padding: 0 0 .25rem 0;
  margin: 0;
  transition: all .1s ease-in-out;
}

.Modal_Container .head_bar .close_btn:hover {
  border-color: #aaa5;
}

.Modal_Container .direct_details {
  background-color: var(--banner-blue-bg);
  color: white;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  margin-bottom: 3px;
}

.Modal_Container .direct_details .mono_outer {
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Modal_Container .direct_details .mono_outer .mono {
  width: 30px;
  height: 30px;
  border: 5px solid;
  transform: rotate(45deg);
  border-radius: .20rem;
  background-color: var(--banner-lightBlue-bg);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
}

.Modal_Container .direct_details .title {
  padding: 0;
  margin: 0;
  font-size: 20px;
  font-weight: 500;
  font-family: 'Jost', sans-serif;
  letter-spacing: .03rem
}

.Modal_Container .direct_details .language {
  padding: 0;
  margin: 0;
  font-size: 13px;
  font-weight: 300;
}

.Modal_Container .direct_details .download_btn {
  border: none;
  outline: none;
  padding: 5px 17px;
  border-radius: 20px;
  box-shadow: 0 3px 4px var(--black2);
  transition: all .1s ease-in-out;
}

.Modal_Container .direct_details .download_btn:active {
  transform: scale(.95);
}

/* Modal Body */
.Modal_Container .body {
  width: 100%;
  height: 83%;
  overflow: auto;
  scrollbar-width: thin; /* Thin scrollbar */
  scrollbar-color: rgba(0, 0, 0, 0.2) rgba(0, 0, 0, 0.05); /* Thumb and track color */
}

.Modal_Container .body .content {
  width: 91%;
  margin: 0 auto;
  border: 1px solid;
}

/* Code Projects Style */
.DevCodePage {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

.DevCodePage .code-type {
  border: 1px solid #a2a2a2;
  border-top-width: 5px;
  font-size: 15px;
  padding: 7px;
  background-color: #f8f8f8;
  transition: all .1s ease;
  cursor: pointer;
}

.DevCodePage .code-type:hover {
  border-color: var(--orange);
}

.DevCode .link-view {
  --url-tracker-color: var(--parablack);
  --url-tracker-hover-color: var(--orange);
  --url-tracker-text-transform: lowercase;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.DevCode .link-view button {
  border: none;
  outline: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  color: var(--orange);
  cursor: pointer;
  transition: all .1s ease;
  text-transform: var(--url-tracker-text-transform);
}

.DevCode .link-view button:hover {
  color: var(--url-tracker-hover-color);
}

.DevCode .link-view span {
  margin-left: 3px;
  color: var(--url-tracker-color);
  text-transform: var(--url-tracker-text-transform);
}

/* Code Snipptt Outlets */
.DevCode .DevCodeOutlets-outer {
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
  margin-bottom: 80px;
}

.DevCodeOutlets-outer .skeleton {
  height: 200px;
}

.DevCodeOutlets-outer .ioi-item {
  --ioi-img-radius: 0.20rem;
  width: 100%;
  height: 200px;
  padding: 8px;
  display: block;
  background-color: #a2a2a233;
  cursor: pointer;
  text-decoration: none;
}

.DevCodeOutlets-outer .ioi-item .ioi-img-outer {
  height: 85%;
  border-radius: var(--ioi-img-radius);
  transition: all .1s ease;
}

.DevCodeOutlets-outer .ioi-item .ioi-img-outer:hover {
  box-shadow: 0 3px 10px #a2a2a2aa;
}

.DevCodeOutlets-outer .ioi-item .ioi-img-outer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: var(--ioi-img-radius);
  text-decoration: none;
}

.ioi-item h4 {
  font-size: 17px;
  font-weight: 400;
  text-transform: capitalize;
  margin: 3px 0 0 0;
  padding: 0;
  display: block;
  width: 100%;
  height: 15%;
  display: flex;
  align-items: center;
  justify-content: start;
  color: var(--orange);
  font-family: 'Jost', sans-serif;
}

/* Right Sidebar */
.dev__main__content__container .sidebar {
  border: 1px solid;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.sidebar .staric-div {
  width: 170px;
  height: 170px;
  transform: skewX(-20deg);
  background-color: var(--orange);
  position: relative;
  margin: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebar .staric-div::before {
  content: '';
  position: absolute;
  width: 80%;
  height: 110%;
  top: -5%;
  left: 0;
  z-index: -1;
  background-color: #F36A3Eaa;
  transform: skewX(40deg);
}

.sidebar .staric-div::after {
  content: '';
  position: absolute;
  width: 80%;
  height: 90%;
  top: 5%;
  left: 20px;
  z-index: -1;
  background-color: #F36A3E77;
  transform: skewX(-45deg);
}

.sidebar .staric-div .icon {
  font-size: 50px;
  color: var(--golden);
  transform: skewX(20deg);
}
