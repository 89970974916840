/* Styles for tablets around 768px */
@media only screen and (max-width: 768px) {
  :root {
    --font-13: 13px;
  }

  .announcement-banner .banner-detail .contact .icon {
    font-size: 9px;
    margin-top: -2px;
  }

  .announcement-banner .banner-detail .contact .text {
    font-size: 11px;
  }

  .announcement-banner .social .icon {
    font-size: 13px;
  }

  .main_header {
    height: 60px;
  }

  .main_header .logo {
    height: 40px;
  }

  .main_header .logo h1 {
    font-size: 35px;
    margin-top: 2px;
  }

  .header-btn-object {
    font-size: var(--font-13);
    padding: 6px 9px;
    margin-left: 10px;
  }

  .main_header menu ul li a {
    padding: 0;
    font-size: var(--font-13);
  }

  .main-banner {
    height: 650px;
  }

  .main-banner .container {
    margin-top: 100px;
    max-width: 95% !important;
  }

  .main-banner .content h5 {
    font-size: 15px;
    width: 240px;
    height: 36px;
  }

  .main-banner .content h2 {
    font-size: 45px;
    text-wrap: nowrap;
    line-height: 50px;
    margin-top: 12px;
  }

  .main-banner .content p {
    margin-top: 12px;
    font-size: var(--font-13);
  }

  .main-banner .visual {
    width: 340px;
  }

  .main-banner .visual img {
    width: 170%;
    margin-left: 65px;
  }

  .main-banner .visual .icon {
    font-size: 35px;
  }

  .main-banner .visual .icon.javascript {
    top: 20px;
    left: 62px;
  }

  .main-banner .visual .icon.php {
    top: 115px;
    left: -20px;
  }

  .main-banner .visual .icon.react {
    top: 227px;
    left: 25px;
  }

  .main-banner .visual .icon.html {
    top: 44px;
    right: 6px;
  }

  .main-banner .visual .icon.css {
    top: 208px;
    right: 25px;
  }

  .home-about-container {
    padding-bottom: 50px;
  }

  .home-about-container .FeatureServiceCard {
    width: calc(100% / 3 - 50px);
    height: 250px;
    padding: 12px;
  }

  .FeatureServiceCard .feature-service-icon {
    font-size: 35px;
  }

  .FeatureServiceCard p {
    font-size: var(--font-13);
  }

  .about-details .about-side.image .image-container {
    width: 350px;
    height: 300px;
  }

  .about-details .about-side.image {
    width: auto;
    min-width: 200px !important;
    height: fit-content;
  }

  .about-details .about-side.image .experience-show-box h4 {
    font-size: 15px;
  }

  .about-details .about-side.image .experience-show-box h4 span:nth-child(1) {
    font-size: 40px;
  }

  .about-details .about-side.image .experience-show-box {
    bottom: -5px;
    right: -30px;
    width: 190px;
    height: 65px;
  }

  .section .type {
    font-size: 11px;
  }

  .section .type .breaker {
    width: 40px;
    height: 4px;
  }

  .about-details {
    align-items: start;
    padding-top: 330px;
  }

  .about-side.section .title {
    font-size: 22px;
    margin: 10px 0 15px 0;
  }

  .about-side.section .detail {
    font-size: 13px;
    line-height: 18px;
  }

  .about-side.section button {
    padding: 5px 15px;
    font-size: 13px;
    margin: 20px 0;
  }

  .about-side.section button::after {
    border: 3px solid #f9f9f9;
    border-bottom-left-radius: 2px;
    box-shadow: 1px 1px 2px 0px #a54525;
  }

  .why-choose-me .choose-visual {
    width: 38%;
  }

  .why-choose-me .choose-data .title {
    font-size: 22px;
  }

  .why-choose-me .choose-data .detail {
    font-size: 13px;
  }

  .choosen-list .ChoosenListItem {
    --ch-it-height: 45px !important; 
  }

  .choosen-list .ChoosenListItem .icon-box span {
    font-size: 25px;
  }

  .choosen-list .ChoosenListItem .list-item-content > h5 {
    font-size: 13px;
    margin: 0;
    line-height: 14px;
  }

  .choosen-list .ChoosenListItem .list-item-content > p {
    font-size: 10px;
  }

  .section-heading {
    padding-left: 40px;
    font-size: 25px;  
    font-family: 'Jost', sans-serif;
  }

  .portfolio-designs .project-box {
    width: 220px;
  }

  .portfolio-designs .project-box img {
    height: 160px;
  }

  .portfolio-designs .project-box a {
    font-size: 15px;
  }

  .portfolio-designs .project-box h4 {
    margin-top: 12px;
  }

  .portfolio-designs .project-box p:nth-child(3) {
    font-size: 11px;
  }

  /* About Page */
  .about-page-body {
    flex-direction: column;
    align-items: center;
  }

  .about-page-body .ab-pg-break.about-container {
    width: 100%;
  }

  .about-page-body .ab-pg-break.about-faq {
    width: 100%;
  }

  .about-faq .faq-list-item input:checked ~ label {
    height: 110px;
  }

  /* Contact Page */
  .contact-page-body .contact-page-header {
    width: 80%;
  }
  
  .contact-page-body .contact-container {
    flex-direction: column;
  }

  .contact-content.contact-form {
    display: none;
  }
}

@media only screen and (max-width: 700px) {
  .main_header .logo h1 {
    display: none;
  }

  .main-banner .visual {
    display: none;
  }

  .home-about-container .feature-services-list {
    flex-direction: column;
    align-items: center;
    position: relative;
  }

  .home-about-container .FeatureServiceCard {
    width: 300px;
    height: 200px;
    margin-top: 50px;
  }

  .about-details {
    padding-top: 100px;
    flex-direction: column;
    align-items: center;
  }

  .FeatureServiceCard h3 {
    margin-top: .2rem;
  }

  .FeatureServiceCard p {
    margin-top: 10px;
  }

  .about-details .about-side.image::before {
    display: none;
  }

  .about-details .about-side {
    width: 60%;
    margin-top: 10px;
  }

  /* Why Choose me Section */
  .why-choose-me {
    flex-direction: column;
    align-items: center;
  }

  /* Portfolio Section */
  .portfolio-designs .project-box {
    width: 180px;
  }

  .portfolio-designs .project-box img {
    height: 130px;
  }

  /* CTA Banner */
  .CTABanner .cta-heading {
    font-size: 30px;
  }
}

@media only screen and (max-width: 425px) {
  .main_header menu ul li a {
    margin: 0 6px;
  }

  .about-details .about-side {
    width: 90%;
  }

  .about-details .about-side.image .image-container {
    margin: 0;
  }

  /* Why Choose Me */
  .why-choose-me .choose-visual,
  .why-choose-me .choose-data {
    width: 90% !important;
    padding-top: 20px;
  }

  /* Portfolio Design */
  .portfolio-designs,
  .section-heading {
    display: none;
  }

  /* CTA Banner */
  .CTABanner {
    margin-top: 40px;
  }

  .CTABanner .cta-heading {
    font-size: 25px;
  }

  .CTABanner .cta-btn {
    --cta-btn-size: 14px;
    width: 150px;
    height: 40px;
    border-radius: 3px;
  }

  /* Contact Page */
  .about-page-body .ab-pg-break.about-container p,
  .about-page-body .ab-pg-break.about-container li {
    font-size: 13px;
  }

  .footer-global .copyright-box {
    font-size: 13px;
  }
  
}