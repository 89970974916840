:root {
  --darkGreen: #30373d;
  --lightGreen: #444d55;
  --whiteE: #eee;
  --whiteD: #ddd;
  --whiteC: #ccc;
  --black36: #363636;
  --black46: #464646;
  --black56: #565656;
}

.blur-bg {
  backdrop-filter: blur(40px);
}

/* Login Style */
.admin-login-container {
  width: 300px;
  margin: 50px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.admin-login-title {
  text-align: center;
  margin-bottom: 20px;
}

.admin-login-form {
  display: flex;
  flex-direction: column;
}

.admin-login-label {
  margin-bottom: 10px;
}

.admin-login-input {
  width: 100%;
  padding: 8px;
  margin-bottom: 20px;
  border: 1px solid var(--whiteC);
  border-radius: 4px;
}

.admin-login-button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: var(--white);
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.admin-login-button:hover {
  background-color: #0056b3;
}

.DashboardLayout img {
  user-select: none;
  -webkit-user-drag: none;
}

/* Dashboard */
.DashboardLayout {
  width: 100%;
  background-image: url('../media/img/black\ gradient.jpg');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  color: var(--white);
}

/* Header */
.DashboardLayout .HeaderDashboard {
  width: 100%;
  height: 60px;
  background-color: var(--darkGreen);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}

.HeaderDashboard .logo {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.HeaderDashboard .logo img {
  width: 50px;
}

.HeaderDashboard .logo .admin {
  color: var(--whiteE);
  margin: 0;
  font-size: 35px;
  font-family: "Yatra One", system-ui;
}

.HeaderDashboard .info_area {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
}

.HeaderDashboard .info_area .icon {
  width: 20px;
  height: 20px;
  color: var(--whiteC);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-left: 10px;
  transition: all .1s ease;
  cursor: pointer;
}

.HeaderDashboard .info_area .icon.external_link:hover {
  color: var(--blue);
}

.HeaderDashboard .info_area .icon.setting:hover {
  transform: rotate(20deg);
  color: var(--white);
}

.HeaderDashboard .info_area .user_info {
  width: 50px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}

.HeaderDashboard .info_area .user_info > .avatar {
  cursor: pointer;
}

.HeaderDashboard .info_area .user_info .avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.HeaderDashboard .info_area .user_info .avatar .avatar_img {
  width: 120%;
  height: 120%;
  object-fit: cover;
}

.HeaderDashboard .info_area .user_info .details {
  position: absolute;
  top: 150%;
  right: 5px;
  z-index: 99;
  padding: 10px 10px 15px 10px;
  width: 200px;
  background-color: var(--black36);
  border-radius: .25rem;
  box-shadow: 2px 5px 13px -4px var(--black);
  visibility: hidden;
  opacity: 0;
  transform: translateY(10px); /* Move down when hidden */
  transition: opacity 0.1s ease, transform 0.3s ease;
}

/* When the details are visible */
.HeaderDashboard .info_area .user_info .details.show {
  visibility: visible;
  opacity: 1;
  transform: translateY(0); /* Move up into position */
}

.HeaderDashboard .info_area .user_info .details::before {
  content: '';
  position: absolute;
  top: -6px;
  right: 13px;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid var(--black36); /* Triangle matches the background */
  z-index: 100; /* Higher than the details container */
}

.HeaderDashboard .info_area .user_info .details .banner {
  height: 50px;
  background-color: var(--black46);
  border-radius: .25rem;
  padding: 22px 10px;
}

.HeaderDashboard .info_area .user_info .details .banner .avatar {
  width: 50px;
  height: 50px;
}

.HeaderDashboard .info_area .user_info .details .admin_name {
  margin: 30px 0 0 0;
  font-size: 15px;
  letter-spacing: .01rem;
  color: var(--whiteE);
}

.HeaderDashboard .info_area .user_info .details ul {
  list-style: none;
  padding: 0;
  margin: 10px 0;
  border-top: 1px solid var(--black46);
}

.HeaderDashboard .info_area .user_info .details ul li a {
  display: block;
  width: 100%;
  height: 30px;
  margin: 3px 0;
  display: flex;
  align-items: center;
  padding-left: 10px;
  text-decoration: none;
  color: var(--whiteD);
  font-size: 14px;
  border-radius: .35rem;
  cursor: pointer;
  transition: all .1s ease-in-out;
}

.HeaderDashboard .info_area .user_info .details ul li a:hover {
  background-color: var(--black46);
}

.HeaderDashboard .info_area .user_info .details .logout_btn {
  border: none;
  outline: none;
  font-size: 13px;
  background-color: var(--java);
  color: var(--whiteE);
  border-radius: .25rem;
  padding: 2px 7px;
}

/* Main Container */
.DashboardLayout .main_container {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.DashboardLayout .main_container .SidebarDashboard {
  width: 18% !important;
  height: 100vh;
  padding: 15px 20px;
  background-color: var(--darkGreen);
}

.SidebarDashboard ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.SidebarDashboard ul li a {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 35px;
  color: var(--whiteD);
  margin: 4px 0;
  font-size: 15px;
  padding: 0 20px;
  border-radius: .25rem;
  text-decoration: none;
  transition: all .1s ease-in-out;
  position: relative;
}

.SidebarDashboard ul li a .icon {
  font-size: 17px;
  padding-bottom: 3px;
}

.SidebarDashboard ul li a .down-arrow {
  position: absolute;
  font-size: 15px;
  right: 15px;
  padding-bottom: 3px;
  transition: all .2s ease;
  opacity: .6;
}

.SidebarDashboard ul li a.active .down-arrow {
  transform: rotate(180deg);
}

.SidebarDashboard ul li a:hover,
.SidebarDashboard ul li a.active {
  background-color: var(--lightGreen);
}

.SidebarDashboard ul li ul {
  margin: 0 10px;
  display: none;
  visibility: hidden;
  opacity: 0;
  transition: all .1s ease-in-out;
}

.SidebarDashboard ul li a.active ~ ul {
  opacity: 1;
  display: block;
  height: auto;
  visibility: visible;
}

.SidebarDashboard ul li ul li a {
  height: 32px;
}

/* Dashboard Main Container */
.DashboardLayout .main_container main {
  width: 88%;
  padding: 20px;
}