* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* 
.bebas-neue-regular {
  font-family: "Bebas Neue", sans-serif;
  font-weight: 400;
  font-style: normal;
} */
/* 
.jost-uniquifier {
  font-family: "Jost", sans-serif;
  font-optical-sizing: auto;
  font-weight: weight;
  font-style: normal;
} */
/*
.bungee-outline-regular {
  font-family: "Bungee Outline", sans-serif;
  font-weight: 400;
  font-style: normal;
} */
/* .yatra-one-regular {
  font-family: "Yatra One", system-ui;
  font-weight: 400;
  font-style: normal;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

img {
  font-size: 13px;
}

:root {
  --white: #ffffff;
  --black: #000000;
  --black2: #262626;
  --black3: #363636;
  --black4: #444444;
  --black5: #555555;
  --black6: #626262;
  --blue: #4169e1;
  --yellow: #FFB30B;
  --orange: #F36A3E;
  --background: #3a3d44;
  --parablack: #696969;
  --react: #6ad3f3;
  --php: #6181b6;
  --java: #c42f30;
  --html: #dc4a29;
  --css: #2b4bdd;
  --selection-bg: #ffb20b8a;
  --not-found: #ff4c4c;
  --golden: #FFDF00;
}

::selection {
  color: var(--white);
  background-color: var(--selection-bg);
}

.selector-for-some-widget {
  box-sizing: content-box;
}

input {
  appearance: none;
  -webkit-appearance: none; /* For Chrome/Safari */
  -moz-appearance: textfield; /* For Firefox */
}

input[type="search"] {
  -webkit-appearance: none; /* Remove default styles */
  -moz-appearance: none; /* Remove default styles */
  appearance: none; /* Remove default styles */
}

/* For WebKit browsers */
input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none; /* Hides the clear button */
  display: none; /* Ensures it does not display */
}

/* For Firefox */
input[type="search"]::-moz-search-results-button {
  display: none; /* Hides the clear button */
}

/* LoadingBar.css */
.loading-bar-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: transparent;
  z-index: 9999;
}

.loading-bar {
  height: 100%;
  background-color: #3498db;
  transition: width 1s ease-in-out;
  width: 0%;
}
.loading-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px; /* Height of the loading bar */
  background-color: orange; /* Change to your preferred color */
  transform: translateX(-100%); /* Start off-screen */
  animation: loading-animation 1s forwards; /* Animate the loading bar */
  z-index: 9999; /* Ensure it stays on top of other elements */
}

@keyframes loading-animation {
  to {
    transform: translateX(0); /* Move to visible position */
  }
}

.ComingSoon {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ComingSoon h1 {
  font-size: 50px;
  color: var(--yellow);
}

.coming-soon-skeleton {
  width: 500px;
  height: 350px;
  margin: auto;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}


/* 404 Error Page */
.ErrorPage404 {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 105px);
  text-align: center;
  padding: 20px;
  transform: scale(0); /* Initially scaled down */
  transition: transform 0.5s ease; /* Smooth scaling effect */
}

.ErrorPage404 .icon {
  font-size: 60px;
  color: var(--not-found);
  animation: ErrorRotateAnimation 2.5s infinite linear;
}

.ErrorPage404 h1 {
  margin: 0;
  font-size: 2rem;
  color: var(--not-found);
  margin-bottom: 10px;
  font-family: 'Arial', sans-serif;
}

.ErrorPage404 p {
  font-size: 1rem;
  color: var(--parablack);
  margin: 0;
  margin-bottom: 20px;
}

.ErrorPage404 button {
  padding: 5px 11px;
  font-size: .8rem;
  background-color: var(--banner-blue-bg);
  color: var(--white);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.ErrorPage404 button:hover {
  background-color: #0056b3;
}

.ErrorPage404 button:active {
  background-color: #003d80;
}

.ErrorPage404 button:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

@keyframes ErrorRotateAnimation {
  0% {
    transform: translateY(0);
    animation-timing-function: ease-out; /* Start slow */
  }
  25% {
    transform: translateY(-10px); /* Higher bounce */
    animation-timing-function: ease-in; /* Accelerate going down */
  }
  50% {
    transform: translateY(0); /* Back to original position */
    animation-timing-function: ease-out; /* Slow down at bottom */
  }
  75% {
    transform: translateY(-5px); /* Smaller bounce */
    animation-timing-function: ease-in; /* Accelerate again */
  }
  100% {
    transform: translateY(0); /* End at original position */
    animation-timing-function: ease-out; /* End slowly */
  }
}

/* Scrollbar Styling for Webkit Browsers (Chrome, Safari) */
.Modal_Container .body::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

.Modal_Container .body::-webkit-scrollbar-thumb {
  background-color: #4CAF50; /* Green scrollbar thumb */
  border-radius: 8px; /* Rounded scrollbar */
}

.Modal_Container .body::-webkit-scrollbar-thumb:hover {
  background-color: #45a049; /* Darker green on hover */
}

.Modal_Container .body::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Light grey track */
}

/* Firefox Scrollbar Styling */
.Modal_Container .body {
  scrollbar-width: thin;
  scrollbar-color: #4CAF50 #f1f1f1; /* Green thumb, light grey track */
}
