.device-width-container {
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
}

.btn-pentagon {
  position: relative;
  padding: 8px 22px;
  background: var(--blue);
  clip-path: polygon(100% 0, 100% 78%, 89% 100%, 0% 100%, 0 0%);
  outline: none;
  border: 1px solid black;
}

/* Announcement Section */
.announcement-banner {
  width: 100%;
  height: 30px;
  background-color: var(--black2);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
}

.announcement-banner .banner-detail {
  display: flex;
  align-items: center;
  color: var(--white);
  gap: 10px;
}

.announcement-banner .banner-detail .contact {
  display: flex;
  align-items: center;
  gap: 5px;
  margin: 0;
}

.announcement-banner .banner-detail .contact .icon {
  font-size: 11px;
}

.announcement-banner .banner-detail .contact:nth-child(2) .icon {
  font-size: 14px;
}

.announcement-banner .banner-detail .contact .text {
  font-size: 13px;
  margin: 0;
}

.announcement-banner .social {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.announcement-banner .social .icon {
  margin: 0 7px;
  font-size: 15px;
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: space-around;
  transition: 0.2s all ease-in-out;
}

.announcement-banner .social .icon:hover {
  color: var(--orange);
}

.blue-outline {
  background-color: transparent;
  border: 1px solid var(--blue);
}

/* Header */
.main_header {
  width: 100%;
  max-width: 1430px;
  position: absolute;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  transition: background 0.3s ease;
}

.main_header .logo {
  height: 75%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  user-select: none;
  text-decoration: none;
  /* font-family: "Jost", sans-serif !important; */
}

.main_header .logo img {
  height: 100%;
  margin-top: -3px;
}

.main_header .logo h1 {
  font-size: 60px;
  color: var(--white);
  font-family: "Bebas Neue", sans-serif !important;
  transition: color 0.2s ease;
  margin: 0;
  text-wrap: nowrap;
}

.main_header > * > * {
  height: 100%;
}

.main_header > div {
  position: relative;
  z-index: 99;
  width: 95%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.main_header menu {
  padding: 0;
  display: flex;
  align-items: center;
}

.main_header menu ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.main_header menu ul li a {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 9px;
  margin: 0 10px;
  text-decoration: none;
  color: var(--white);
  position: relative;
}

.main_header menu ul li:nth-last-child(1) a::after {
  display: none;
}

.main_header menu ul li a::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -3px;
  width: 100%;
  height: 3px;
  background-color: var(--orange);
  transform: scaleX(0);
  transform-origin: left;
  transition: all 0.3s ease-in-out;
}

.main_header menu ul li a:hover::after,
.main_header menu ul li a.active::after {
  transform: scaleX(1);
}

.header-btn-object {
  --color-header-btn-object: var(--java);
  border: none;
  outline: none;
  padding: 8px 15px;
  margin-left: 10px;
  color: var(--color-header-btn-object);
  font-weight: 500;
  cursor: not-allowed !important;
  position: relative;
  background-color: transparent;
  backdrop-filter: blur(20px);
  text-wrap: nowrap;
}

.header-btn-object::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.3s;
  background-color: transparent;
  border: none;
  border-top: 2px solid;
  border-left: 2px solid;
  border-color: var(--color-header-btn-object);
}

.header-btn-object::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border: none;
  border-right: 2px solid;
  border-bottom: 2px solid;
  border-color: var(--color-header-btn-object);
  background-color: transparent;
  transition: all 0.2s;
}

.header-btn-object:hover::after,
.header-btn-object:hover::before {
  width: 5px;
  height: 5px;
}

/* Banner Styling*/
.main-banner {
  width: 100%;
  height: 850px;
  position: relative;
  overflow: hidden;
}

.main-banner::before {
  content: url("./media/img/wave\ \(1\).png");
  position: absolute;
  width: 100%;
  height: 100%;
  margin-top: -80px;
  z-index: -1;
}

.main-banner .container {
  width: 100%;
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
}

.main-banner .content h5 {
  width: 300px;
  height: 45px;
  margin: 0;
  font-size: 19px;
  font-weight: 400;
  border-radius: 50px;
  padding: 5px 15px 5px 5px;
  color: var(--white);
  background-color: var(--black4);
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: left;
}

.main-banner .content h5 span {
  padding: 5px 10px;
  margin-right: 5px;
  border-radius: 50px;
  background-color: var(--orange);
}

.main-banner .content h2 {
  text-transform: uppercase;
  font-size: 60px;
  margin: 30px 0 0 0;
  line-height: 70px;
}

.main-banner .content .outlined-heading {
  font-family: "Bungee Outline", sans-serif !important;
  font-weight: 400;
}

.main-banner .content p {
  color: var(--parablack);
}

.main-banner .visual {
  width: 500px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-banner .visual .icon {
  position: absolute;
  font-size: 45px;
  animation: float 3s ease-in-out infinite;
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

.main-banner .visual .icon.hidden {
  opacity: 0;
}

/* Animation for floating and fading */
@keyframes float {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  50% {
    transform: translateY(-10px);
    opacity: 0.7;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.main-banner .visual .icon.javascript {
  top: 70px;
  left: 50px;
  color: var(--java);
}

.main-banner .visual .icon.php {
  top: 150px;
  left: -70px;
  color: var(--php);
}

.main-banner .visual .icon.react {
  top: 250px;
  left: -20px;
  color: var(--react);
}

.main-banner .visual .icon.html {
  top: 110px;
  right: 100px;
  color: var(--html);
}

.main-banner .visual .icon.css {
  top: 220px;
  right: 30px;
  color: var(--css);
}

/* Home About Section */
.home-about-container {
  height: auto;
  padding-bottom: 100px;
  background-color: #f9f9f9;
  position: relative;
}

.home-about-container .feature-services-list {
  width: 100%;
  margin-top: -100px;
  position: absolute;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
}

.home-about-container .FeatureServiceCard {
  background-color: var(--yellow);
  width: calc(100% / 3 - 155px);
  margin: 5px;
  padding: 20px;
  color: var(--white);
  position: relative;
  z-index: 10;
}

.home-about-container .FeatureServiceCard::before {
  content: "";
  position: absolute;
  top: -35px;
  right: -35px;
  width: 80px;
  height: 80px;
  z-index: 9;
  border-radius: 50%;
  backdrop-filter: blur(10px);
}

.home-about-container .FeatureServiceCard::after {
  content: "";
  position: absolute;
  left: -10px;
  bottom: -10px;
  width: 93%;
  height: 93%;
  z-index: -1;
  border: 1px solid var(--yellow);
}

.FeatureServiceCard .feature-service-icon {
  font-size: 40px;
}

.FeatureServiceCard h3 {
  font-size: 20px;
  margin: 0;
}

.FeatureServiceCard p {
  opacity: 0.9;
  font-weight: 400;
}

/* About Details */
.about-details {
  width: 100%;
  padding-top: 380px;
  padding-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.about-details .about-side {
  width: calc(100% / 2 - 80px);
  position: relative;
}

.about-details .about-side.image {
  min-width: 490px;
}

.about-details .about-side.image::before {
  content: "";
  position: absolute;
  top: -10px;
  left: -10px;
  width: 100px;
  height: 100px;
  background-image: url("./media/img/cubes.jpg");
  background-size: 400% auto;
  background-position: center;
  z-index: 1;
  border-radius: 5px;
  opacity: 0.8;
}

.about-details .about-side.image .experience-show-box {
  position: absolute;
  z-index: 5;
  bottom: -10px;
  right: -10px;
  width: 230px;
  height: 80px;
  background-color: var(--orange);
  background-image: linear-gradient(var(--orange), var(--yellow));
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.about-details .about-side.image .experience-show-box h4 {
  font-size: 20px;
  color: var(--white);
  display: flex;
  align-items: center;
  gap: 10px;
}

.about-details .about-side.image .experience-show-box h4 span:nth-child(1) {
  font-size: 45px;
}

.about-details .about-side.image .image-container {
  width: 470px;
  height: 380px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 3;
  margin: 10px 0 0 20px;
}

.about-details .about-side.image .image-container img {
  width: 100%;
  object-fit: cover; /* or another value like contain, fill, etc. */
  object-position: center;
  border-radius: 5px;
}

.about-details .about-side.content {
  padding: 10px 5px;
}

.section .type {
  font-size: 13px;
  color: var(--orange);
  text-transform: uppercase;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 15px;
}

.section .type .breaker {
  width: 70px;
  height: 5px;
  background-color: #cfcfcf;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 8px;
}

.section .type .breaker span:nth-child(1) {
  width: 58%;
  height: 100%;
  background-color: var(--orange);
}

.section .type .breaker span:nth-child(2) {
  width: 15%;
  height: 100%;
  background-color: var(--orange);
}

.about-side.section .title {
  font-size: 30px;
  text-transform: capitalize;
  margin: 20px 0 18px 0;
}

.about-side.section .detail {
  font-size: 17px;
  text-transform: capitalize;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  line-height: 23px;
  margin: 0;
  color: var(--parablack);
}

.about-side.section button {
  padding: 10px 25px;
  font-size: 16px;
  margin: 30px 0;
  color: var(--white);
  /* background-image: linear-gradient(to right, #003, #000); */
  background-color: var(--orange);
  border: none;
  outline: none;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.about-side.section button::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  background-color: var(--orange);
  box-shadow: 2px 2px 8px 1px #a54525;
  border: 5px solid #f9f9f9;
  border-bottom-left-radius: 3px;
  transform: scale(0);
  transform-origin: top right;
  transition: transform 0.3s ease;
}

.about-side.section button:hover::after {
  transform: scale(1);
}

/* Success Section */
.SuccessCard {
  display: flex;
  padding: 100px 0;
  align-items: center;
  justify-content: center;
}

.SuccessCard .success-container {
  width: 500px;
  height: 300px;
  position: relative;
}

.success-container .success-box {
  width: fit-content;
  color: white;
  background-color: var(--orange);
  letter-spacing: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
}

.success-container .success-box.b-1,
.success-container .success-box.b-2 {
  font-size: 45px;
}

.success-container .success-box.b-1 {
  width: 150px;
  height: 60px;
  margin: 5px;
}

.success-container .success-box.b-2 {
  height: 60px;
  margin: -1px 0 0 80px;
  transform: rotate(90deg);
}

.success-container .success-box.b-3 {
  height: 60px;
  font-size: 30px;
  padding: 0 10px;
  margin: 60px 0 0 144px;
  text-transform: capitalize;
}

.success-container .success-box.b-4 {
  height: 60px;
  font-size: 30px;
  padding: 0 10px;
  margin: -58px 0 0 405px;
  font-size: 40px;
  color: var(--orange);
  background-color: transparent;
}

/* Portfolio */
#portfolio {
  margin-top: 50px;
}

.portfolio-container {
  margin: 10px;
  padding-top: 100px;
}

.section-heading {
  padding: 0 70px;
  color: var(--black);
}

.porfolio-img-bottom {
  right: 0;
  bottom: 0;
  z-index: -1;
}

.section-head-content > p {
  width: 100%;
  text-align: center;
}

.section-head-content > p:nth-child(1) {
  color: var(--orange);
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 5px;
}

.section-head-content > p:nth-child(2) {
  color: #222222;
  font-size: 40px;
  line-height: 48px;
  margin-bottom: 20px;
}

.section-head-content > p:nth-child(3) {
  color: #888888;
  margin-bottom: 30px;
  width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.portfolio-designs {
  width: 90%;
  height: 350px;
}

.portfolio-designs .project-box {
  width: 380px;
  height: auto;
  margin: 10px;
  padding-bottom: 10px;
  border-top: 3px solid var(--orange);
}

.portfolio-designs .project-box img {
  width: 100%;
  height: 200px;
  /* box-shadow: 0 4px 10px -3px #888888; */
}

.portfolio-designs .project-box a {
  width: auto;
  text-decoration: none;
  color: var(--orange);
}

.portfolio-designs .project-box h4 {
  width: 100%;
  text-align: center;
  font-size: 18px;
  margin: 20px 0 0 0;
  font-weight: 500;
  font-family: "Jost", sans-serif !important;
}

.portfolio-designs .project-box p:nth-child(3) {
  width: auto;
  margin: 3px 0 0 0;
  font-size: 14px;
  color: #888888;
  text-align: center;
}

/* CTA Styling */
.CTABanner {
  width: 100%;
  height: 500px;
  padding: 20px;
  background-color: var(--php);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.CTABanner .cta-heading {
  font-size: 50px;
  color: var(--white);
  text-transform: uppercase;
  text-align: center;
  margin: -20px 0 0 0;
}

.CTABanner .cta-btn {
  --cta-btn-size: 17px;
  --transition-duration: .2s;
  --transition-style: ease-in-out;
  --cta-transform-origin: top;
  margin-top: 20px;
  border: none;
  outline: none;
  font-size: var(--cta-btn-size);
  width: 190px;
  height: 50px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  perspective: 1000px;
  overflow: hidden;
  cursor: pointer;
}

.CTABanner .cta-btn::before {
  content: 'Contact Us Today!';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: var(--cta-btn-size);
  color: var(--black);
  background-color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all var(--transition-duration) var(--transition-style);
  transform-origin: var(--cta-transform-origin);
  backface-visibility: hidden;
  transition-delay: .1s;
}

.CTABanner .cta-btn:hover::before {
  transition-delay: 0s;
  transform: rotateX(90deg);
}

.CTABanner .cta-btn::after {
  content: 'Contact Us Today!';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  font-size: var(--cta-btn-size);
  color: var(--white);
  background-color: var(--orange);
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotateX(-90deg);
  backface-visibility: hidden;
  transition: all var(--transition-duration) var(--transition-style);
  transform-origin: bottom;
}

.CTABanner .cta-btn:hover::after {
  transition-delay: .1s;
  transform: rotateX(0);
}

/* Service Page Styling */
.service-page {
  width: 100%;
  padding: 120px 0;
}

.service-page > h2 {
  font-size: 50px;
  text-align: center;
  margin: 10px auto;
  font-family: "Bebas Neue", sans-serif;
}

.service-page .service-outer {
  width: 85%;
  padding: 20px 10px;
  margin: 40px auto 20px auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.service-outer .service-card {
  width: 400px;
  border: 1px solid var(--html);
  height: 50px;
  margin: 0 0 35px 0;
  border-radius: 0.2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: "Jost", sans-serif;
  transition: all 0.1s ease-in-out;
  cursor: pointer;
}

.service-outer .service-card:hover .icon-box {
  color: var(--white);
  background-color: var(--html);
}

.service-outer .service-card .icon-box {
  width: 60px;
  height: 100%;
  font-size: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  color: var(--html);
  border-right: 1px solid var(--html);
  transition: all 0.1s ease-in-out;
}

.service-outer .service-card h3 {
  margin: 0;
  font-size: 19px;
  color: var(--html);
  font-weight: 600;
  transition: all 0.1s ease-in-out;
}

.service-page .service-reach {
  display: flex;
  align-items: center;
  justify-content: center;
}

.service-reach a {
  text-decoration: none;
}

.service-reach button {
  width: 200px;
  height: 50px;
  gap: 15px;
  font-size: 20px;
  color: var(--white);
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 0.3rem;
  background-color: var(--orange);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .2s;
  box-shadow: 0 8px 5px -6px var(--black);
  position: relative;
  overflow: hidden;
}

.service-reach button:active {
  transform: scale(0.960);
}

.service-reach button::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 200%;
  z-index: 2;
  background-image: linear-gradient(
    110deg,
    transparent,
    #ffffff60,
    transparent
  );
  transform: translateX(-100%);
}

.service-reach button:hover::after {
  transform: translateX(100%);
  transition: all .5s;
}

/* About Page Styling */
.who-we-are-style {
  font-size: 50px;
  text-align: center;
  margin: 0 auto;
  margin-top: 130px;
  font-family: "Bebas Neue", sans-serif;
}

.about-page-body {
  width: 100%;
  height: auto;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 50px;
  padding: 15px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.about-page-body .ab-pg-break {
  height: fit-content;
  background-color: #fafafa;
  border-radius: 0.25rem;
  padding: 10px;
  padding-bottom: 20px;
}

.about-page-body .ab-pg-break.about-container {
  width: 70%;
  padding: 10px 20px 20px 20px;
}

.about-container > h4 {
  font-size: 30px;
  margin: 10px 0;
}

.about-container h2 {
  font-size: 19px;
  font-weight: 500;
  margin: 10px 0;
}

.about-container p {
  color: var(--parablack);
}

.about-container a {
  text-decoration: none;
  color: var(--blue);
}

/* About FAQ Side */
.about-page-body .ab-pg-break.about-faq {
  width: 22%;
}

.ab-pg-break.about-faq h4 {
  font-size: 20px;
  margin: 10px 0 20px 0;
}

.about-faq .faq-list-item {
  position: relative;
}

.about-faq .faq-list-item input {
  display: none;
}

.about-faq .faq-list-item input ~ label {
  cursor: pointer;
}

.about-faq .faq-list-item input:checked ~ label h5 {
  color: var(--blue);
}

.about-faq .faq-list-item label {
  width: 95%;
  height: 50px;
  margin: 8px 0;
  padding: 4px 10px;
  overflow: hidden;
  display: inline-block;
  border: 1px solid var(--parablack);
  border-radius: 0.5rem;
  transition: all 0.3s cubic-bezier(0.25, -0.44, 0, 1.05);
  user-select: none;
}

.about-faq .faq-list-item input:checked ~ label {
  cursor: default;
  height: 130px;
  border-color: var(--blue);
}

.about-faq .faq-list-item label h5 {
  height: 45px;
  line-height: 20px;
  padding-bottom: 5px;
  display: flex;
  align-items: center;
  margin: 0;
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
}

.about-faq .faq-list-item label p {
  border-top: 1px solid var(--blue);
  margin: 0;
  font-size: 14px;
  padding: 5px 0;
  color: var(--parablack);
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

/* Why Choose Us Styling */
.why-choose-me {
  display: flex;
  width: 100%; /* Ensures the container takes up the full width */
  padding-top: 80px;
  justify-content: center; /* Adjusts spacing between inner components */
}

.why-choose-me .choose-visual {
  width: 40% !important;
}

.choose-visual img {
  width: 100% !important;
}

.why-choose-me .choose-data {
  width: 55%;
  font-family: 'Jost', sans-serif;
}

.why-choose-me .choose-data .title {
  text-decoration: none;
}

.choose-data .choosen-list {
  width: 100%;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
}

.choosen-list .ChoosenListItem {
  --ch-it-height: 70px;
  --ch-it-list-color: var(--orange);
  width: 47%;
  height: var(--ch-it-height);
  margin: 15px 0;
  display: flex;
  align-items: center;
  justify-content: left;
}

.choosen-list .ChoosenListItem .icon-box {
  width: var(--ch-it-height) !important;
  height: var(--ch-it-height);
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px 0;
  border: 1px solid var(--ch-it-list-color);
  position: relative;
  background-color: var(--white);
}

.choosen-list .ChoosenListItem .icon-box::before {
  content: '';
  position: absolute;
  top: -5px;
  left: -5px;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: var(--ch-it-list-color);
  border-radius: 10px 0;
}

.choosen-list .ChoosenListItem .icon-box span {
  font-size: 30px;
  color: var(--ch-it-list-color);
}

.choosen-list .ChoosenListItem:hover .icon-box span {
  transform: rotateY(360deg); /* Remove the quotes around 90deg */
  transition: transform 0.3s ease-in-out; /* Smooth transition */
  transform-style: preserve-3d; /* Ensure 3D transform works correctly */
  backface-visibility: hidden; /* Hide the back of the element when flipped */
}

.choosen-list .ChoosenListItem .list-item-content {
  max-width: 75%;
  height: 100%;
  padding: 3px 0;
}

.choosen-list .ChoosenListItem .list-item-content > h5 {
  font-size: 18px;
  margin: 0;
  color: var(--black2);
  font-family: Arial, Helvetica, sans-serif;
}

.choosen-list .ChoosenListItem .list-item-content > p {
  font-size: 13px;
  margin: 4px 0;
  color: var(--parablack);
}

/* Footer Global */
.footer-global {
  width: 100% !important;
  height: 120px;
  background-color: var(--black2);
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-global .copyright-box {
  font-size: 14px;
  color: var(--white);
  margin: 0;
  opacity: 0.7;
  user-select: none;
}

/* Footer Dev */
.FooterDev {
  width: 100%;
  min-height: 100px;
}

.FooterDev .upper {
  height: 50px;
  background-color: var(--blue);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--whiteE);
}

.FooterDev .upper h4 {
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
}

.FooterDev .lower {
  background-color: rgb(32, 38, 57);
  background-size: 300% 300%;
  padding: 20px;
  padding-bottom: 40px;
}

.FooterDev .lower ul {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  list-style: none;
  padding: 0;
  margin: 0;
}

.FooterDev .lower ul li a {
  display: inline-block;
  color: var(--whiteC);
  text-decoration: none;
  opacity: .8;
  transition: all .1s ease;
}

.FooterDev .lower li a:hover {
  /* text-decoration: underline; */
  opacity: 1;
}

.FooterDev .lower p {
  margin: 0;
  padding: 0;
  font-size: 13px;
  color: var(--whiteC);
  opacity: .7;
  text-align: center;
  margin-top: 20px;
  user-select: none;
}

/* Contact Page Styling */
.contact-page-body {
  width: 100%;
}

.contact-title-style {
  font-size: 50px;
  text-align: center;
  margin: 0 auto;
  margin-top: 130px;
  font-family: "Bebas Neue", sans-serif;
}

.contact-page-body .contact-page-header {
  width: 50%;
  margin: 0 auto;
  text-align: center;
}

.contact-page-header h2 {
  margin: 0;
  font-size: 50px;
  color: var(--black2);
}

.contact-page-header p {
  margin: 10px 0;
  font-size: 14px;
  color: var(--parablack);
}

.contact-page-body .contact-container {
  width: 80%;
  margin: 50px auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: nowrap;
}

.contact-page-body .contact-container .contact-content {
  width: 100%;
  padding: 10px;
}

/* Contact Links */
.contact-content.contact-details {
  font-family: Arial, Helvetica, sans-serif;
}

.contact-details h4 {
  font-size: 25px;
  margin: 20px 0;
  color: var(--black2);
}

.contact-details h5 {
  font-size: 17px;
  font-weight: 600;
  margin: 20px 0 10px 0;
  /* color: var(--blue); */
  border-bottom: 2px solid;
  width: fit-content;
}

.contact-details h5:nth-child(5) {
  margin-top: 25px;
}

.contact-details .contact-social {
  display: flex;
  align-items: center;
  font-size: 17px;
  margin: 10px 0;
}

.contact-details .contact-social > * {
  margin-right: 8px;
  color: var(--black2);
}

.contact-details .details .contact-list {
  gap: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}

.contact-details .details .contact-list p {
  margin: 0;
  font-size: 15px;
  color: var(--black2);
}

.contact-details .google-map {
  width: 100%;
  margin-top: 20px;
}

.contact-details .google-map hr {
  width: 60%;
  height: 3px;
  border: none;
  outline: none;
  margin: 30px auto;
  border-radius: 5px;
  background-color: var(--parablack);
}

/* Contact Form */
.contact-content.contact-form {
  display: flex;
  justify-content: center;
}

.contact-content.contact-form form {
  width: 500px;
  height: fit-content;
  max-width: 90%;
  margin-top: 20px;
  padding: 15px 30px 30px 30px;
  display: flex;
  border-radius: 5px;
  flex-direction: column;
  border: 8px solid var(--blue);
}

.contact-content.contact-form form h3 {
  font-size: 28px;
  margin: 10px 0 20px 0;
  font-weight: 700;
  color: var(--black2);
  font-family: Arial, Helvetica, sans-serif;
}

.contact-content.contact-form form > div {
  width: 100%;
  height: 35px;
  display: flex;
  position: relative;
  align-items: center;
  margin-bottom: 10px;
}

.contact-content.contact-form form > div:nth-child(4) {
  height: auto;
  display: block;
}

.contact-form form > div label {
  top: 7px;
  left: 10px;
  cursor: text;
  font-size: 15px;
  position: absolute;
  transition: all 0.3s;
  color: var(--parablack);
}

.contact-form form > div:nth-child(4) label {
  top: 8px;
}

.contact-form form > div input,
.contact-form form > div textarea {
  width: 100% !important;
  outline: none;
  padding: 3px 6px;
  font-size: 15px;
  transition: all 0.2s;
  border-radius: 3px;
  border: 1px solid var(--parablack);
  font-family: Arial, Helvetica, sans-serif;
}

.contact-form form > div input:focus,
.contact-form form > div textarea:focus {
  border-color: var(--blue);
}

.contact-form form > div input:focus ~ label,
.contact-form form > div textarea:focus ~ label {
  top: -9px;
  padding: 0 4px;
  font-size: 11px;
  color: var(--blue);
  background-color: var(--white);
}

.contact-form form > div input:valid ~ label,
.contact-form form > div textarea:valid ~ label {
  top: -9px;
  padding: 0 4px;
  font-size: 11px;
  background-color: var(--white);
}

.contact-form form > div textarea {
  height: 180px;
  resize: none;
  padding-top: 8px;
}

.contact-form form > div input {
  height: 35px;
}

.contact-content.contact-form form .form-button {
  width: 100%;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-content.contact-form form .form-button button {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
  cursor: pointer;
  font-size: 14px;
  color: var(--white);
  border-radius: 3px;
  transition: all 0.2s;
  background-color: var(--blue);
  position: relative;
  overflow: hidden;
}

.contact-content.contact-form form .form-button button::before {
  content: "";
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--blue);
  transform: translateX(-100%);
}

/* 
.contact-content.contact-form form .form-button button:hover {
  background-color: #3c5cbe;
} */

.contact-content.contact-form form .form-button button:active::before {
  transform: translateX(0);
  transition: transform 0.3s;
}

/* Newsletter Section Styling */
.NewsletterSection {
  width: 100%;
  padding: 60px 20px;
  background-color: #f1f1f1;
}

.NewsletterSection form {
  width: 550px;
  height: 50px;
  border: 2px solid var(--orange);
  border-radius: 12px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 0 auto;
  box-shadow: 0px 5px 15px -2px var(--parablack);
}

.NewsletterSection form input {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  font-size: 16px;
  color: var(--parablack);
  padding: 0 13px;
}

.NewsletterSection form input::placeholder {
  color: var(--parablack);
  opacity: 0.7;
}

.NewsletterSection form input:hover {
  background-color: #bad8ff27;
  transition: all 0.2s;
}

.NewsletterSection form input:focus {
  background-color: var(--white);
}

.NewsletterSection form button {
  height: 100%;
  padding: 0 20px;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  font-size: 17px;
  color: var(--white);
  background-color: var(--orange);
}

.NewsletterSection form button:active {
  cursor: pointer;
}
