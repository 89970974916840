
@keyframes skeleton {
  100% {
    transform: translateX(100%);
  }
}

.skeleton {
  --base-color: #ebebeb;
  --highlight-color: #f5f5f5;
  --animation-duration: 1.5s;
  --animation-direction: normal;
  --pseudo-element-display: block;
  background-color: var(--base-color);
  width: 100%;
  min-height: 10px;
  border-radius: 0.25rem;
  display: inline-flex;
  line-height: 1;
  position: relative;
  -webkit-user-select: none;
  user-select: none;
  overflow: hidden;
}

.skeleton::after {
  content: ' ';
  display: var(--pseudo-element-display);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-repeat: no-repeat;
  background-image: linear-gradient(
    90deg,
    var(--base-color),
    var(--highlight-color),
    var(--base-color)
  ) !important;
  transform: translateX(-100%);

  animation-name: skeleton;
  animation-direction: var(--animation-direction);
  animation-duration: var(--animation-duration);
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

/* Nav Skeleton Styling */
.NavSkeleton {
  width: 500px;
  height: 45px;
}

.NavSkeleton .skeleton {
  height: 100%;
}

/* Service Page Skeleton */
.service-page .service-outer .skeleton {
  width: 400px;
  height: 50px;
  margin: 0 0 35px 0;
  border-radius: 0.2rem;
}

/* About Page Skeleton */
.AboutPageTextSkeleton > div {
  height: 18px;
}

.AboutPageFAQSkeleton > div {
  height: 38px;
  margin-bottom: 10px;
}

/* Contact Page Skeleton */
.contact-skeleton {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-head-skeleton {
  width: 60%;
  height: 100px;
  border-radius: 10px !important;
}

.contact-skeleton .c-s-side {
  width: 100%;
  margin: 25px auto 50px auto;
  gap: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.contact-skeleton .c-s-side > div {
  width: 45%;
  display: flex;
  flex-direction: column;
}

.contact-skeleton .c-s-side > div:nth-child(1) > div {
  height: 16px;
  margin-bottom: 3px;
}

.contact-skeleton .c-s-side > div:nth-child(1) > div:nth-child(1) {
  height: 25px;
  width: 60%;
  margin-bottom: 5px;
}

.contact-skeleton .c-s-side > div:nth-child(1) > div:nth-child(2) {
  height: 150px;
  margin-bottom: 30px;
}

.contact-skeleton .c-s-side > div:nth-child(1) > div:nth-last-child(1) {
  width: 100%;
  height: 280px;
  border-radius: 0.5rem;
}

.contact-skeleton .c-s-side > div:nth-child(2) > .skeleton {
  width: 80%;
  margin: 0 auto;
  height: 490px;
}

/* Dashboard Loading Bar */
/* Loader bar style */
.loading-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background-color: #3498db; /* Customize the color */
  z-index: 99999; /* Ensure it's on top */
  animation: loading .5s ease linear infinite; /* Use ease to maintain smoothness */
}

/* Loading Overlay Style */
.loading-overlay {
  position: fixed; /* Cover the entire screen */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent white */
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  z-index: 99999; /* Ensure it's on top of everything */
  font-size: 24px; /* Customize text size */
  color: #3498db; /* Customize text color */
}

/* Loader animation */
@keyframes loading {
  0% {
    width: 0; /* Start from 0% */
  }
  20% {
    width: 35%; /* Move to 35% */
  }
  40% {
    width: 35%; /* Hold at 35% (Pause) */
  }
  60% {
    width: 75%; /* Move to 75% */
  }
  80% {
    width: 75%; /* Hold at 75% (Pause) */
  }
  90% {
    width: 50%; /* Move to 50% */
  }
  100% {
    width: 100%; /* Finally reach 100% */
  }
}
